<template>
  <div v-if="user" class="v-stack h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">First Name</label>
          <input v-model="user.firstName" type="text" />
          <label class="text-left required">Last Name</label>
          <input v-model="user.lastName" type="text" />
          <template v-if="user.accessLevel > 4">
            <template v-if="$store.state.accessLevel > 9">
              <label class="text-left required">Account type</label>
              <select v-model="user.accessLevel">
                <!-- <option value="0">Freelancer</option> -->
                <!-- <option value="1">Client</option> -->
                <option value="5">Crew</option>
                <option value="9">Production</option>
                <option value="10">Admin</option>
              </select>
            </template>
            <label class="text-left">Birthday</label>
            <InputDatePicker v-model="user.birthday"></InputDatePicker>
          </template>
          <label class="text-left required">Phone Number</label>
          <InputPhoneNumber v-model="user.phoneNumber"></InputPhoneNumber>
          <label class="text-left required">Email</label>
          <input v-model="user.email" type="email" />
          <template v-if="user.accessLevel > 4">
            <label class="text-left">Address</label>
            <textarea v-model="user.address" />
            <label class="text-left">Guardian</label>
            <textarea v-model="user.guardian" placeholder="name, number" />
          </template>
          <template v-if="user.accessLevel == 0 || user.accessLevel > 4">
            <div class="pane-horizontal gap-3">
              <label class="text-left">Profession</label>
              <label v-if="user.accessLevel == 0" class="text-left"
                >Daily Fee</label
              >
            </div>
            <div
              class="gap-3"
              v-bind:class="[
                user.accessLevel == 0 ? 'pane-horizontal' : 'pane-right',
              ]"
              v-for="(proffession, index) in user.categories"
              :key="proffession.id"
            >
              <select v-model="proffession.name">
                <option v-for="category in categories" :key="category">
                  {{ category }}
                </option>
              </select>
              <div class="pane-right gap-3">
                <input
                  v-if="user.accessLevel == 0"
                  v-model="proffession.dailyFee"
                  type="number"
                />
                <button class="edit" @click="removeProffession(index)">
                  X
                </button>
              </div>
            </div>
            <button class="add" @click="addProffession()">Add</button>
            <template v-if="user.accessLevel < 5">
              <label class="text-left">Rating</label>
              <select v-model="user.rating">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </template>
            <label class="text-left">Description</label>
            <textarea v-model="user.description"></textarea>
          </template>
          <template v-if="user.accessLevel == 1">
            <label class="text-left">Company</label>
            <select v-model="user.client">
              <option
                v-for="client in clients"
                :key="client._id"
                :value="client._id"
              >
                {{ client.name }}
              </option>
            </select>
          </template>
          <div class="h-stack h-space-between">
            <button
              v-if="$store.state.accessLevel > 9"
              class="edit"
              @click="lockAccount()"
            >
              {{ user.locked ? "Unlock" : "Lock" }} Account
            </button>
            <div v-else></div>
            <button class="submit" @click="submit()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="user._id == $store.state.id">
      <a class="heading-title-1">Password</a>
      <div class="card light">
        <div class="content h-stretch">
          <div class="v-stack gap-3 h-stretch">
            <label class="text-left">Old Password</label>
            <input v-model="oldPassword" type="password" />
            <label class="text-left">New Password</label>
            <input v-model="newPassword" type="password" />
            <input v-model="newPasswordCheck" type="password" />
            <label v-if="newPassword != newPasswordCheck"
              >Password doesn't match</label
            >
            <div class="h-stack h-end">
              <button class="submit" @click="submitPassword()">Change</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import InputPhoneNumber from "@/components/InputPhoneNumber.vue";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  components: {
    InputPhoneNumber,
    InputDatePicker,
  },
  data() {
    return {
      categories: [],
      user: null,
      oldPassword: "",
      newPassword: "",
      newPasswordCheck: "",
      clients: [],
    };
  },
  props: ["id"],
  methods: {
    ...mapActions([
      "getUser",
      "updateUser",
      "changePassword",
      "getClients",
      "getPricelistItems",
      "lockUser",
    ]),
    lockAccount() {
      if (this.user) {
        this.lockUser(this.user._id)
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addProffession() {
      this.user.categories.push({
        name: "",
        dailyFee: 0,
        id: uuidv4(),
      });
    },
    removeProffession(index) {
      this.user.categories.splice(index, 1);
    },
    submit() {
      this.updateUser({
        _id: this.user._id,
        address: this.user.address,
        birthday: this.user.birthday,
        categories: this.user.categories,
        client: this.user.client,
        description: this.user.description,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        guardian: this.user.guardian,
        phoneNumber: this.user.phoneNumber,
        rating: this.user.rating,
        accessLevel: this.user.accessLevel,
        email: this.user.email,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitPassword() {
      if (
        this.oldPassword.length > 0 &&
        this.newPassword.length > 0 &&
        this.newPassword == this.newPasswordCheck
      ) {
        this.changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    refresh() {
      this.getUser(this.id)
        .then((user) => {
          this.user = user;
          if (user.client) {
            this.user.client = user.client._id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();

    this.getClients()
      .then((clients) => {
        this.clients = clients;
      })
      .catch((error) => {
        console.log(error);
      });

    this.getPricelistItems()
      .then((items) => {
        const services = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .map((item) => item.name)
          )
        );

        this.categories = services.sort((a, b) => a.localeCompare(b, "cs"));
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
