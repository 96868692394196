<template>
  <div class="pane-left">
    <div class="h-stack">
      <input
        @focus="$event.target.select()"
        ref="firstPart"
        class="phone"
        v-model="firstPart"
        type="text"
      />
      <input
        @focus="$event.target.select()"
        ref="secondPart"
        class="phone"
        v-model="secondPart"
        type="text"
      />
    </div>
    <input
      @focus="$event.target.select()"
      ref="thirdPart"
      class="phone"
      v-model="thirdPart"
      type="text"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstPart: "",
      secondPart: "",
      thirdPart: "",
      ready: false,
    };
  },
  watch: {
    firstPart() {
      if (!this.ready) {
        return;
      }

      this.firstPart = this.sanitizeValue(this.firstPart);
      this.updateNumber();
      if (this.firstPart.length > 2) {
        this.$refs.secondPart.focus();
      }
    },
    secondPart() {
      if (!this.ready) {
        return;
      }

      this.secondPart = this.sanitizeValue(this.secondPart);
      this.updateNumber();
      if (this.secondPart.length > 2) {
        this.$refs.thirdPart.focus();
      }
    },
    thirdPart() {
      if (!this.ready) {
        return;
      }

      this.thirdPart = this.sanitizeValue(this.thirdPart);
      this.updateNumber();
    },
  },
  model: {
    prop: "number",
    event: "change",
  },
  props: {
    number: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    sanitizeValue(value) {
      const sanitizedValue = value.replace(/[^0-9]/g, "").substring(0, 3);
      return sanitizedValue;
    },
    updateNumber() {
      this.$emit(
        "change",
        Number(this.firstPart + this.secondPart + this.thirdPart)
      );
    },
    refresh() {
      this.ready = false;
      this.firstPart = String(this.number).substring(0, 3);
      this.secondPart = String(this.number).substring(3, 6);
      this.thirdPart = String(this.number).substring(6, 9);

      this.$nextTick(() => {
        this.ready = true;
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
.phone {
  width: 80px !important;
}
</style>
